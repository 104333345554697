import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Spinner from './components/Loaders/Spinner';

import { useRolePermission } from './hooks/useRolePermission';

import RequiresAuth from './pages/auth/RequiresAuth';
import RequiresUnAuth from './pages/auth/RequiresUnAuth';

import { hideLoader, showLoader } from '@/redux/slice/siteLoaderSlice';
import { ReactComponent as TrustNetwork } from '@icons/trust_network_logo.svg';
import ContactSupport from './pages/HelpAndResources/ContactSupport';
import ReadmeRedirection from './pages/ReadmeRedirection';
import { getActiveUserDataApi } from './pages/auth/services';
import {
  getActiveOrganization,
  getActiveRole,
  getIsAuthenticated,
} from './redux/slice/authSlice';
import { setIsChromeBrowser } from './redux/slice/browserType.slice';
import { giveDocsRedirectionPermission } from './redux/slice/docsSlice';
import { getAuthToken } from './redux/slice/tokenSlice';
import {
  ORGANIZATION_TYPES,
  PRIVATE_NAVIGATION,
  PUBLIC_NAVIGATION,
  ROLES,
} from './utils/constants';

export type RouteObjType = {
  path?: string;
  element: JSX.Element;
  children?: RouteObject[];
  errorElement?: JSX.Element;
  feature?: string | boolean;
  permission?: string | boolean;
  organizationType?: string | boolean;
  btnNavigatePath?: string;
  btnTitle?: string;
  sideBarId?: number;
};

const MockBankConsentAuthorization = React.lazy(
  () => import('./pages/projects/payment-setup/mockBankConsentAuthorization')
);
const MockBankLogin = React.lazy(
  () => import('./pages/projects/payment-setup/mockBankLogin')
);
const PaymentSetup = React.lazy(
  () => import('./pages/projects/payment-setup/paymentSetup')
);
const SetupDeclined = React.lazy(
  () => import('./pages/projects/payment-setup/setupDeclined')
);
const SetupSuccess = React.lazy(
  () => import('./pages/projects/payment-setup/setupSuccess')
);

const Login = React.lazy(() => import('@pages/auth/Login'));
const VerifySecureCode = React.lazy(() => import('@pages/auth/VerifySecureCode'));
const SignUp = React.lazy(() => import('./pages/auth/Signup'));
const Success = React.lazy(() => import('./components/Success'));
const Groups = React.lazy(() => import('./pages/groups/groups'));
const RolesAndPermissions = React.lazy(
  () => import('./pages/groups/rolesAndPermissions')
);
const ProjectInvite = React.lazy(() => import('@pages/auth/ProjectInvite'));
const Team = React.lazy(() => import('./pages/groups/team'));
const InviteUser = React.lazy(() => import('./pages/organization/InviteUser'));
const List = React.lazy(() => import('./pages/organization/List'));
const SetupUsers = React.lazy(() => import('./pages/organization/SetupUsers'));
const CreateOrganization = React.lazy(
  () => import('./pages/organization/CreateOrganization')
);
const PageNotFound = React.lazy(() => import('./pages/PageNotFound'));
const ConfirmEmail = React.lazy(() => import('./pages/auth/ConfirmEmail'));
const ResetPassword = React.lazy(() => import('./pages/auth/ResetPassword'));
const SetNewPassword = React.lazy(() => import('./pages/auth/SetNewPassword'));
const VerifyResetEmail = React.lazy(() => import('./pages/auth/VerifyResetEmail'));

const UseCaseSelect = React.lazy(() => import('./pages/useCaseSelect'));
const PartnerUsers = React.lazy(() => import('./pages/users/PartnerUsers'));
const CreateProject = React.lazy(() => import('./pages/projects/create-project/create'));
const EditProject = React.lazy(() => import('./pages/projects/editProject'));
const ViewProjectConfig = React.lazy(
  () => import('./pages/projects/view-project/viewProject')
);
const Collaborators = React.lazy(
  () => import('./pages/projects/create-project/components/collaborators')
);
const ProjectSetting = React.lazy(() => import('./pages/projects/projectSetting'));
const ManageProjects = React.lazy(() => import('./pages/projects/manageProjects'));
const ShopperConfiguration = React.lazy(() => import('./pages/projects/configure'));
const Dashboard = React.lazy(() => import('./pages/dashboard/dashboard'));
const GetStartedCheckoutAndRefund = React.lazy(
  () => import('./pages/projects/checkout-and-refund/getStarted')
);
const GetStartedSTI = React.lazy(
  () => import('./pages/projects/shopper-trust-index/getStarted')
);

const Routes = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const token = useSelector(getAuthToken);
  const { getActiveUser } = getActiveUserDataApi();
  const activeOrganization = useSelector(getActiveOrganization);
  const activeRole = useSelector(getActiveRole);

  const publicRoutes = [
    {
      path: PUBLIC_NAVIGATION.login,
      element: <Login />,
    },
    {
      path: PUBLIC_NAVIGATION.verifySecureCode,
      element: <VerifySecureCode />,
    },
    {
      path: PUBLIC_NAVIGATION.readmeDocs,
      element: token ? <ReadmeRedirection /> : <Login />,
    },
    {
      path: PUBLIC_NAVIGATION.signUp,
      element: <SignUp />,
      btnTitle: 'Login',
      btnNavigatePath: PUBLIC_NAVIGATION.login,
    },
    {
      path: PUBLIC_NAVIGATION.confirmEmail,
      element: <ConfirmEmail />,
      btnTitle: '',
    },
    {
      path: PUBLIC_NAVIGATION.signUpSuccess,
      element: (
        <div>
          <div className="w-full flex justify-center items-center">
            <div className="my-3 w-8/12">
              <TrustNetwork />
            </div>
          </div>
          <div className="w-full flex justify-center">
            <div className="w-4/12">
              <Success
                header="Account setup successfully !"
                message="All done! You will automatically be redirected or you can click the continue
        button."
                btnTitle="Continue"
                redirectionUrl="/"
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      path: PUBLIC_NAVIGATION.resetPasswordSuccess,
      element: (
        <div>
          <div className="w-full flex justify-center items-center">
            <div className="my-3 w-8/12">
              <TrustNetwork />
            </div>
          </div>
          <div className="w-full flex justify-center">
            <div className="w-4/12">
              <Success
                header="Password reset successfully!"
                message="All done! You will automatically be redirected to the log in page or you can click the log in button."
                btnTitle="Log in"
                redirectionUrl={PUBLIC_NAVIGATION.login}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      path: PUBLIC_NAVIGATION.enrollment,
      element: <Login />,
    },
    {
      path: PUBLIC_NAVIGATION.useCases,
      element: <UseCaseSelect />,
    },
    {
      path: PUBLIC_NAVIGATION.resetPassword,
      btnNavigatePath: PUBLIC_NAVIGATION.login,
      btnTitle: 'Login',
      element: <ResetPassword />,
    },
    {
      path: PUBLIC_NAVIGATION.resetEmailVerify,
      btnNavigatePath: PUBLIC_NAVIGATION.login,
      btnTitle: 'Login',
      element: <VerifyResetEmail />,
    },
    {
      path: PUBLIC_NAVIGATION.setNewPassword,
      btnNavigatePath: PUBLIC_NAVIGATION.login,
      btnTitle: 'Login',
      element: <SetNewPassword />,
    },
    {
      path: PUBLIC_NAVIGATION.projectInvite,
      element: <ProjectInvite />,
    },
  ];

  useEffect(() => {
    const browserType = navigator.userAgent.toLowerCase();
    dispatch(showLoader({ header: '', description: '' }));
    if (
      browserType.includes('chrome') &&
      browserType.includes('safari') &&
      !browserType.includes('edge')
    ) {
      dispatch(setIsChromeBrowser({ isChromeBrowser: true }));
    } else {
      dispatch(setIsChromeBrowser({ isChromeBrowser: false }));
    }
    getUserData();
  }, [token]);

  const getUserData = async () => {
    if (token) {
      await getActiveUser();
      dispatch(hideLoader());
    }

    if (
      token &&
      isAuthenticated &&
      !activeOrganization?.city &&
      [...Object.values(PUBLIC_NAVIGATION)].findIndex((data) => {
        return data.split('/')[1] === window.location.pathname.split('/')[1];
      }) === -1 &&
      window.location.pathname !== PRIVATE_NAVIGATION.organization.setup.path &&
      activeRole &&
      activeRole === ROLES.ADMIN &&
      window.location.pathname !== PUBLIC_NAVIGATION.readmeDocs
    ) {
      window.location.href = PRIVATE_NAVIGATION.organization.setup.path;
    }

    if (
      !isAuthenticated &&
      token == null &&
      [...Object.values(PUBLIC_NAVIGATION)].findIndex((data) => {
        return data.split('/')[1] === window.location.pathname.split('/')[1];
      }) === -1 &&
      window.location.pathname !== PUBLIC_NAVIGATION.readmeDocs
    ) {
      window.location.href = PUBLIC_NAVIGATION.login;
    }

    if (
      token &&
      isAuthenticated &&
      [...Object.values(PUBLIC_NAVIGATION)].findIndex((data) => {
        return (
          !['signup-success', 'signup', 'project-invite'].includes(data.split('/')[1]) &&
          data.split('/')[1] === window.location.pathname.split('/')[1]
        );
      }) > -1 &&
      window.location.pathname !== PUBLIC_NAVIGATION.readmeDocs
    ) {
      if (!activeOrganization?.city && activeRole === ROLES.ADMIN) {
        window.location.href = PRIVATE_NAVIGATION.organization.setup.path;
      } else {
        window.location.href = PRIVATE_NAVIGATION.home.path;
      }
    }

    if (
      !isAuthenticated &&
      token == null &&
      window.location.pathname === PUBLIC_NAVIGATION.readmeDocs
    ) {
      dispatch(giveDocsRedirectionPermission());
    }
  };

  const applySuspense = (element: JSX.Element) => {
    return (
      <Suspense
        fallback={
          <div className="w-screen h-screen flex justify-center items-center">
            <Spinner className="!h-12 !w-12" />
          </div>
        }
      >
        {element}
      </Suspense>
    );
  };

  const applyRequiresAuth = (routes: RouteObjType[]): RouteObjType[] => {
    return routes.map((route) => {
      return {
        ...route,
        element: applySuspense(
          <RequiresAuth sidebarId={route.sideBarId} path={route.path}>
            {route.element}
          </RequiresAuth>
        ),
      };
    });
  };

  const applyRequiresUnAuth = (routes: RouteObjType[]): RouteObjType[] => {
    return routes.map((route) => ({
      ...route,
      feature: true,
      permission: true,
      organizationType: true,
      element: applySuspense(
        <RequiresUnAuth
          btnText={route?.btnTitle ?? ''}
          btnNavigatePath={route?.btnNavigatePath ?? ''}
        >
          {route.element}
        </RequiresUnAuth>
      ),
    }));
  };

  const authenticatedRouted: RouteObjType[] = [
    {
      path: PRIVATE_NAVIGATION.useCase.list.path,
      sideBarId: PRIVATE_NAVIGATION.useCase.list.activeSidebar,
      feature: PRIVATE_NAVIGATION.useCase.list.feature,
      permission: PRIVATE_NAVIGATION.useCase.list.permission,
      organizationType: PRIVATE_NAVIGATION.useCase.list.organization,
      element: <UseCaseSelect />,
    },
    {
      path: PRIVATE_NAVIGATION.useCase.product.path,
      sideBarId: PRIVATE_NAVIGATION.useCase.product.activeSidebar,
      feature: PRIVATE_NAVIGATION.useCase.product.feature,
      permission: PRIVATE_NAVIGATION.useCase.product.permission,
      organizationType: PRIVATE_NAVIGATION.useCase.product.organization,
      element: <UseCaseSelect />,
    },
    {
      path: PRIVATE_NAVIGATION.home.path,
      sideBarId: PRIVATE_NAVIGATION.home.activeSidebar,
      feature: PRIVATE_NAVIGATION.home.feature,
      permission: PRIVATE_NAVIGATION.home.permission,
      organizationType: PRIVATE_NAVIGATION.home.organization,
      element: <Dashboard />,
    },
    {
      path: '/',
      sideBarId: PRIVATE_NAVIGATION.home.activeSidebar,
      feature: PRIVATE_NAVIGATION.home.feature,
      permission: PRIVATE_NAVIGATION.home.permission,
      organizationType: PRIVATE_NAVIGATION.home.organization,
      element: <Dashboard />,
    },
    {
      path: PRIVATE_NAVIGATION.users.list.path,
      sideBarId: PRIVATE_NAVIGATION.users.list.activeSidebar,
      feature: PRIVATE_NAVIGATION.users.list.feature,
      permission: PRIVATE_NAVIGATION.users.list.permission,
      organizationType: PRIVATE_NAVIGATION.users.list.organization,
      element: <PartnerUsers />,
    },
    {
      path: PRIVATE_NAVIGATION.users.view.path,
      sideBarId: PRIVATE_NAVIGATION.users.view.activeSidebar,
      feature: PRIVATE_NAVIGATION.users.view.feature,
      permission: PRIVATE_NAVIGATION.users.view.permission,
      organizationType: PRIVATE_NAVIGATION.users.view.organization,
      element: <PartnerUsers />,
    },
    {
      path: PRIVATE_NAVIGATION.groups.list.path,
      sideBarId: PRIVATE_NAVIGATION.groups.list.activeSidebar,
      feature: PRIVATE_NAVIGATION.groups.list.feature,
      permission: PRIVATE_NAVIGATION.groups.list.permission,
      organizationType: PRIVATE_NAVIGATION.groups.list.organization,
      element: <Groups />,
    },
    {
      path: PRIVATE_NAVIGATION.groups.view.path,
      sideBarId: PRIVATE_NAVIGATION.groups.view.activeSidebar,
      feature: PRIVATE_NAVIGATION.groups.view.feature,
      permission: PRIVATE_NAVIGATION.groups.view.permission,
      organizationType: PRIVATE_NAVIGATION.groups.view.organization,
      element: <Team />,
    },
    {
      path: PRIVATE_NAVIGATION.groups.roles.path,
      sideBarId: PRIVATE_NAVIGATION.groups.roles.activeSidebar,
      feature: PRIVATE_NAVIGATION.groups.roles.feature,
      permission: PRIVATE_NAVIGATION.groups.roles.permission,
      organizationType: PRIVATE_NAVIGATION.groups.roles.organization,
      element: <RolesAndPermissions />,
    },
    {
      path: PRIVATE_NAVIGATION.projects.list.path,
      sideBarId: PRIVATE_NAVIGATION.projects.list.activeSidebar,
      feature: PRIVATE_NAVIGATION.projects.list.feature,
      permission: PRIVATE_NAVIGATION.projects.list.permission,
      organizationType: PRIVATE_NAVIGATION.projects.list.organization,
      element: <ManageProjects />,
    },
    {
      path: PRIVATE_NAVIGATION.projects.add.path,
      sideBarId: PRIVATE_NAVIGATION.projects.add.activeSidebar,
      feature: PRIVATE_NAVIGATION.projects.add.feature,
      permission: PRIVATE_NAVIGATION.projects.add.permission,
      organizationType: PRIVATE_NAVIGATION.projects.add.organization,
      element: <CreateProject />,
    },
    {
      path: PRIVATE_NAVIGATION.projects.edit.path,
      sideBarId: PRIVATE_NAVIGATION.projects.edit.activeSidebar,
      feature: PRIVATE_NAVIGATION.projects.edit.feature,
      permission: PRIVATE_NAVIGATION.projects.edit.permission,
      organizationType: PRIVATE_NAVIGATION.projects.edit.organization,
      element: <EditProject />,
    },
    {
      path: PRIVATE_NAVIGATION.projects.view.path,
      sideBarId: PRIVATE_NAVIGATION.projects.view.activeSidebar,
      feature: PRIVATE_NAVIGATION.projects.view.feature,
      permission: PRIVATE_NAVIGATION.projects.view.permission,
      organizationType: PRIVATE_NAVIGATION.projects.view.organization,
      element: <ViewProjectConfig />,
    },
    {
      path: PRIVATE_NAVIGATION.projects.invite.path,
      sideBarId: PRIVATE_NAVIGATION.projects.invite.activeSidebar,
      feature: PRIVATE_NAVIGATION.projects.invite.feature,
      permission: PRIVATE_NAVIGATION.projects.invite.permission,
      organizationType: PRIVATE_NAVIGATION.projects.invite.organization,
      element: <Collaborators />,
    },
    {
      path: PRIVATE_NAVIGATION.projects.setting.path,
      feature: PRIVATE_NAVIGATION.projects.setting.feature,
      permission: PRIVATE_NAVIGATION.projects.setting.permission,
      organizationType: PRIVATE_NAVIGATION.projects.setting.organization,
      element: <ProjectSetting />,
    },
    {
      path: PRIVATE_NAVIGATION.projects.sti.getStarted.path,
      sideBarId: PRIVATE_NAVIGATION.projects.sti.getStarted.activeSidebar,
      feature: PRIVATE_NAVIGATION.projects.sti.getStarted.feature,
      permission: PRIVATE_NAVIGATION.projects.sti.getStarted.permission,
      organizationType: PRIVATE_NAVIGATION.projects.sti.getStarted.organization,
      element: <GetStartedSTI />,
    },
    {
      path: PRIVATE_NAVIGATION.projects.cr.getStarted.path,
      sideBarId: PRIVATE_NAVIGATION.projects.cr.getStarted.activeSidebar,
      feature: PRIVATE_NAVIGATION.projects.cr.getStarted.feature,
      permission: PRIVATE_NAVIGATION.projects.cr.getStarted.permission,
      organizationType: PRIVATE_NAVIGATION.projects.cr.getStarted.organization,
      element: <GetStartedCheckoutAndRefund />,
    },
    {
      path: PRIVATE_NAVIGATION.projects.configure.path,
      sideBarId: PRIVATE_NAVIGATION.projects.configure.activeSidebar,
      feature: PRIVATE_NAVIGATION.projects.configure.feature,
      permission: PRIVATE_NAVIGATION.projects.configure.permission,
      organizationType: PRIVATE_NAVIGATION.projects.configure.organization,
      element: <ShopperConfiguration />,
    },
    {
      path: PRIVATE_NAVIGATION.projects.manage.path,
      sideBarId: PRIVATE_NAVIGATION.projects.manage.activeSidebar,
      feature: PRIVATE_NAVIGATION.projects.manage.feature,
      permission: PRIVATE_NAVIGATION.projects.manage.permission,
      organizationType: PRIVATE_NAVIGATION.projects.manage.organization,
      element: <ManageProjects />,
    },
    {
      path: PRIVATE_NAVIGATION.setupPayment.list.path,
      sideBarId: PRIVATE_NAVIGATION.setupPayment.list.activeSidebar,
      feature: PRIVATE_NAVIGATION.setupPayment.list.feature,
      permission: PRIVATE_NAVIGATION.setupPayment.list.permission,
      organizationType: PRIVATE_NAVIGATION.setupPayment.list.organization,
      element: <PaymentSetup />,
    },
    {
      path: PRIVATE_NAVIGATION.setupPayment.bankLogin.path,
      sideBarId: PRIVATE_NAVIGATION.setupPayment.bankLogin.activeSidebar,
      feature: PRIVATE_NAVIGATION.setupPayment.bankLogin.feature,
      permission: PRIVATE_NAVIGATION.setupPayment.bankLogin.permission,
      organizationType: PRIVATE_NAVIGATION.setupPayment.bankLogin.organization,
      element: <MockBankLogin />,
    },
    {
      path: PRIVATE_NAVIGATION.setupPayment.bankConsent.path,
      sideBarId: PRIVATE_NAVIGATION.setupPayment.bankConsent.activeSidebar,
      feature: PRIVATE_NAVIGATION.setupPayment.bankConsent.feature,
      permission: PRIVATE_NAVIGATION.setupPayment.bankConsent.permission,
      organizationType: PRIVATE_NAVIGATION.setupPayment.bankConsent.organization,
      element: <MockBankConsentAuthorization />,
    },
    {
      path: PRIVATE_NAVIGATION.setupPayment.setupSuccess.path,
      sideBarId: PRIVATE_NAVIGATION.setupPayment.setupSuccess.activeSidebar,
      feature: PRIVATE_NAVIGATION.setupPayment.setupSuccess.feature,
      permission: PRIVATE_NAVIGATION.setupPayment.setupSuccess.permission,
      organizationType: PRIVATE_NAVIGATION.setupPayment.setupSuccess.organization,
      element: <SetupSuccess />,
    },
    {
      path: PRIVATE_NAVIGATION.setupPayment.setupDeclined.path,
      sideBarId: PRIVATE_NAVIGATION.setupPayment.setupDeclined.activeSidebar,
      feature: PRIVATE_NAVIGATION.setupPayment.setupDeclined.feature,
      permission: PRIVATE_NAVIGATION.setupPayment.setupDeclined.permission,
      organizationType: PRIVATE_NAVIGATION.setupPayment.setupDeclined.organization,
      element: <SetupDeclined />,
    },
    {
      path: PRIVATE_NAVIGATION.organization.list.path,
      sideBarId: PRIVATE_NAVIGATION.organization.list.activeSidebar,
      feature: PRIVATE_NAVIGATION.organization.list.feature,
      permission: PRIVATE_NAVIGATION.organization.list.permission,
      organizationType: PRIVATE_NAVIGATION.organization.list.organization,
      element: <List />,
    },
    {
      path: PRIVATE_NAVIGATION.organization.add.path,
      sideBarId: PRIVATE_NAVIGATION.organization.add.activeSidebar,
      feature: PRIVATE_NAVIGATION.organization.add.feature,
      permission: PRIVATE_NAVIGATION.organization.add.permission,
      organizationType: PRIVATE_NAVIGATION.organization.add.organization,
      element:
        activeOrganization?.organization_type === ORGANIZATION_TYPES.NETWORK_MASTER ? (
          <CreateOrganization
            description="Give this partner organisation a name."
            placeholder="Organisation name"
            submitBtnText="Create organisation"
            title="Create organisation"
          />
        ) : (
          <CreateOrganization
            description="Give this merchant a name."
            placeholder="Merchant name"
            submitBtnText="Create merchant"
            title="Add a merchant"
          />
        ),
    },
    {
      path: PRIVATE_NAVIGATION.organization.setup.path,
      sideBarId: PRIVATE_NAVIGATION.organization.setup.activeSidebar,
      feature: PRIVATE_NAVIGATION.organization.setup.feature,
      permission: PRIVATE_NAVIGATION.organization.setup.permission,
      organizationType: PRIVATE_NAVIGATION.organization.setup.organization,
      element: <SetupUsers />,
    },
    {
      path: PRIVATE_NAVIGATION.organization.invite.path,
      sideBarId: PRIVATE_NAVIGATION.organization.invite.activeSidebar,
      feature: PRIVATE_NAVIGATION.organization.invite.feature,
      permission: PRIVATE_NAVIGATION.organization.invite.permission,
      organizationType: PRIVATE_NAVIGATION.organization.invite.organization,
      element: (
        <InviteUser
          title="Invite User"
          isAdmin={false}
          description="Add or invite members to this organisation."
        />
      ),
    },
    {
      path: PRIVATE_NAVIGATION.organization.inviteAdmin.path,
      sideBarId: PRIVATE_NAVIGATION.organization.inviteAdmin.activeSidebar,
      feature: PRIVATE_NAVIGATION.organization.inviteAdmin.feature,
      permission: PRIVATE_NAVIGATION.organization.inviteAdmin.permission,
      organizationType: PRIVATE_NAVIGATION.organization.inviteAdmin.organization,
      element: (
        <InviteUser
          title="Invite Admin"
          isAdmin={true}
          description="Invite an Admin user to join and takeover setup of this organisation."
        />
      ),
    },
    {
      path: PRIVATE_NAVIGATION.contactSupport.path,
      sideBarId: PRIVATE_NAVIGATION.contactSupport.activeSidebar,
      feature: PRIVATE_NAVIGATION.contactSupport.feature,
      permission: PRIVATE_NAVIGATION.contactSupport.permission,
      organizationType: PRIVATE_NAVIGATION.contactSupport.organization,
      element: <ContactSupport />,
    },
    {
      path: PRIVATE_NAVIGATION.auth.signup.path,
      sideBarId: PRIVATE_NAVIGATION.auth.signup.activeSidebar,
      feature: PRIVATE_NAVIGATION.auth.signup.feature,
      permission: PRIVATE_NAVIGATION.auth.signup.permission,
      organizationType: PRIVATE_NAVIGATION.auth.signup.organization,
      element: <SignUp />,
    },
    {
      path: PRIVATE_NAVIGATION.auth.signupsuccess.path,
      sideBarId: PRIVATE_NAVIGATION.auth.signupsuccess.activeSidebar,
      feature: PRIVATE_NAVIGATION.auth.signupsuccess.feature,
      permission: PRIVATE_NAVIGATION.auth.signupsuccess.permission,
      organizationType: PRIVATE_NAVIGATION.auth.signupsuccess.organization,
      element: (
        <div>
          <div className="w-full flex justify-center items-center">
            <div className="my-3 w-8/12">
              <TrustNetwork />
            </div>
          </div>
          <div className="w-full flex justify-center">
            <div className="w-4/12">
              <Success
                header="Account setup successfully !"
                message="All done! You will automatically be redirected or you can click the continue
        button."
                btnTitle="Continue"
                redirectionUrl="/"
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      path: '*',
      feature: true,
      permission: true,
      organizationType: true,
      element: <PageNotFound />,
    },
  ];
  const AuthenticationRoutes = applyRequiresUnAuth(publicRoutes);
  const authenticatedRoutes = applyRequiresAuth(authenticatedRouted);
  const finalRoutes = [...AuthenticationRoutes, ...authenticatedRoutes].filter(
    (route) => {
      if (route?.feature && route?.permission) {
        return useRolePermission({
          featureName: route.feature,
          permissionNameArray: route.permission,
          organizationType: route.organizationType,
        });
      }
      return false;
    }
  );
  const router = createBrowserRouter(finalRoutes);
  return <RouterProvider router={router} />;
};

export default Routes;
