import NameBadge from '@/components/Badge/NameBadge';
// import { ReactComponent as PaymentPersonalizationIcon } from '@icons/payment_personalization.svg';
// import { ReactComponent as ReturnsOrchestratorIcon } from '@icons/returns_ochestrator.svg';
// import { ReactComponent as IdentityIcon } from '@icons/tamperproof.svg';
import { ScoreBubble } from '@/pages/dashboard/components/shoppersCharts';
import { Config } from '@/redux/slice/projectSlice';
import moment from 'moment';
import {
  config_type,
  CONSUMER_TRUST_INDEX,
  PAYEE_TRUST_INDEX,
  // IDENTITY_AND_AUTH,
  // PAYMENT_PERSONALIZATION,
  RETURNS_ORCHESTRATOR,
  ROLES,
  ShopperConfigSequence,
} from './constants';

export const getRandomInt = (max?: number) => {
  return Math.floor(Math.random() * (max ?? 10000000));
};

export const generateLast12Months = () => {
  const months = [];
  let currentMonth = moment();

  for (let i = 0; i < 12; i++) {
    months.push({
      label: currentMonth.format(`MMM 'YY`),
      value: currentMonth.format('YYYY-MM'),
    });
    currentMonth = currentMonth.subtract(1, 'month');
  }
  return months;
};

export const generateLastMonths = (number: number = 8) => {
  const months = [];
  let currentMonth = moment();

  for (let i = 0; i < number; i++) {
    months.push(currentMonth.format(`MMM`));
    currentMonth = currentMonth.subtract(1, 'month');
  }
  return months;
};

export const getClassNames = (...classes: (string | undefined)[]) => {
  return classes.filter(Boolean).join(' ');
};

export const toCamelCase = (str: string) => {
  return str
    .replace(/\s(.)/g, function (a) {
      return a.toUpperCase();
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, function (b) {
      return b.toLowerCase();
    });
};

export const URLPattern2 =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
export const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/;

export const URLPattern =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g;

export const getRiskColor = (
  risk: string,
  inactive: boolean | undefined = false,
  isPayeeProduct: boolean | undefined = false,
  isConsentActive: boolean | undefined = true
) => {
  if (inactive) return 'bg-grey-content-tertiary';
  if (!isConsentActive) return 'bg-grey-content-secondary';
  switch (risk) {
    case 'trusted':
      return isPayeeProduct ? 'bg-tblue' : 'bg-risk-green';
    case 'medium':
      return 'bg-trud-orange';
    case 'high':
      return 'bg-risk-red';
    case 'low':
      return 'bg-tblue';
    default:
      return 'bg-grey-content-tertiary';
  }
};

export const getRiskTitle = (risk: string, isPayeeProduct?: boolean) => {
  switch (risk) {
    case 'trusted':
    case 'LOW_RISK':
      return isPayeeProduct ? 'Trusted Payee' : 'Trusted Consumer';
    case 'medium':
    case 'MEDIUM_RISK':
      return isPayeeProduct ? 'Medium Risk Payee' : 'Medium Risk Consumer';
    case 'high':
    case 'HIGH_RISK':
      return isPayeeProduct ? 'High Risk Payee' : 'High Risk Consumer';
    default:
      return '';
  }
};
export const getRiskWithScore = (score: number) => {
  if (score >= 80) {
    return 'trusted';
  } else if (score >= 70) {
    return 'medium';
  } else {
    return 'high';
  }
};

export const getScoreColor = (score: number, inactive = false) => {
  if (inactive) {
    return 'text-grey-content-tertiary';
  } else if (score >= 80) {
    return 'text-score-green';
  } else if (score >= 70) {
    return 'text-trud-orange';
  } else {
    return 'text-red-alert';
  }
};

export const getRiskLevel = (risk_level: string) => {
  if (risk_level === 'LOW_RISK_CONSUMER') {
    return 'trusted';
  } else if (risk_level === 'MEDIUM_RISK_CONSUMER') {
    return 'medium';
  } else {
    return 'high';
  }
};

export const getRiskLevelColor = (
  risk_level: string,
  inactive = false,
  isPayeeProduct = false,
  isConsentActive = true
) => {
  if (inactive) {
    return 'text-grey-content-tertiary';
  } else if (!isConsentActive) {
    return 'text-grey-content-secondary';
  } else if (risk_level === 'LOW_RISK_CONSUMER') {
    return isPayeeProduct ? 'text-tblue' : 'text-score-green';
  } else if (risk_level === 'MEDIUM_RISK_CONSUMER') {
    return 'text-trud-orange';
  } else {
    return 'text-red-alert';
  }
};

export const scorePercentage = (score: number) => {
  return (
    <span
      className={`text-${score >= 0 ? 'score-green' : 'red-alert'} font-medium text-sm leading-sm`}
    >
      {score > 0 ? '+' : ''}
      {score}%
    </span>
  );
};

export const pecks = {
  STI: ['Consumer Trust Index Configuration', 'Consumer Trust Network Search'],
  CRM: [
    'Consumer Trust Index Configuration',
    'Instant Refunds Orchestrator',
    'Trusted Consumer Network Search and Analytics',
  ],
};

export const getLocalStorageData = (storageName: string) => {
  const data = localStorage.getItem(storageName);
  return data ? JSON.parse(data) : null;
};

export const setLocalStorageData = (storageName: string, data: string) => {
  localStorage.setItem(storageName, data);
};

interface ProjectType {
  name: string;
  type: string;
  description: string;
  pecks: string[];
  icon: React.ReactComponentElement<any, any>;
}
export const projectTypes: ProjectType[] = [
  {
    name: 'Consumer Trust Index',
    type: CONSUMER_TRUST_INDEX,
    description:
      'A fraud risk score for new and returning consumers, to differentiate trusted consumer from high-risk consumers.',
    pecks: [
      'Consumer identifier',
      'Network search and analytics',
      'Data sharing',
      'Consumer Trust Index with Network Intelligence',
    ],
    icon: (
      <ScoreBubble
        {...{ score: 100, risk_level: 'LOW_RISK_CONSUMER', isPayeeProduct: false }}
      />
    ),
  },
  {
    name: 'Payee Trust Index',
    type: PAYEE_TRUST_INDEX,
    description:
      'A fraud risk assessment indicating whether the payee account in a transfer is linked to previous APP fraud and provides a confirmation of payee details.',
    pecks: [
      'Payee identifier',
      'Network search and analytics',
      'Network intelligence on known APP fraudsters',
      'Confirmation of Payee',
    ],
    icon: (
      <ScoreBubble
        {...{ score: 100, risk_level: 'LOW_RISK_CONSUMER', isPayeeProduct: true }}
      />
    ),
  },
  // {
  //   name: 'Refunds Orchestrator',
  //   type: RETURNS_ORCHESTRATOR,
  //   description:
  //     'An orchestrator helping operations team make automated and assisted decisions for refunds based on consumer fraud risk.',
  //   pecks: [
  //     'Consumer Trust Index',
  //     'Returns Orchestrator with alerts',
  //     'Network Search and Analytics',
  //   ],
  //   icon: <ReturnsOrchestratorIcon className="" />,
  // },
  // {
  //   name: 'Identity and authentication',
  //   type: IDENTITY_AND_AUTH,
  //   description:
  //     'Secure consumer accounts from login to returns, with streamlined passkeys linked directly to your web domain.',
  //   pecks: ['Consumer account creation ', 'Consumer authentication'],
  //   icon: <IdentityIcon className="" />,
  // },
  // {
  //   name: 'Payment Personalisation',
  //   type: PAYMENT_PERSONALIZATION,
  //   description:
  //     'Automatically personalise routing for payments based on consumer fraud risk profile, at checkout and refunds',
  //   pecks: ['Consumer Trust Index', 'Returns Orchestrator'],
  //   icon: <PaymentPersonalizationIcon className="" />,
  // },
];

export const modeColors: Record<string, string> = {
  LIVE: 'bg-green-alert',
  SANDBOX: 'bg-trud-orange',
};

export const addDays = (days: number) => {
  const result = new Date();
  result.setDate(result.getDate() + days);
  return result.toDateString();
};

export const members = [
  {
    id: 0,
    firstName: 'Lerato',
    lastName: 'Matsio',
    email: 'lerato@trudenty.com',
    lastSeen: '10 secs ago',
    role: 'admin',
    active: false,
  },
  {
    id: 1,
    firstName: 'Marco',
    lastName: 'Kome',
    email: 'marco@trudenty.com',
    lastSeen: 'Yesterday at 16:30',
    role: 'viewer',
    active: true,
  },
  {
    id: 2,
    firstName: 'Tuttuh',
    lastName: 'Adegun',
    email: 'tuttuh@trudenty.com',
    lastSeen: '10 secs ago',
    role: 'admin',
    active: false,
  },
  {
    id: 3,
    firstName: 'Ose Yaw',
    lastName: 'Ababio',
    email: 'ababio@trudenty.com',
    lastSeen: 'Yesterday at 16:30',
    role: 'viewer',
    active: true,
  },
  {
    id: 4,
    firstName: 'Amy',
    lastName: 'Fowler',
    email: 'amy@trudenty.com',
    lastSeen: '10 secs ago',
    role: 'admin',
    active: false,
  },
  {
    id: 5,
    firstName: 'Brian',
    lastName: 'Goldberg',
    email: 'brian@trudenty.com',
    lastSeen: '3 days ago',
    role: 'viewer',
    active: true,
  },
];

export const projectsOld = [
  {
    id: 1,
    name: 'Consumer Trust Index',
    description:
      'Safeguarding the entire online marketplace, ensuring a secure and seamless shopping environment for all.',
    products: CONSUMER_TRUST_INDEX,
    environment: 'LIVE',
    lastPublished: '',
  },
  {
    id: 2,
    name: 'Returns Orchestrator',
    description:
      'An orchestrator helping operations team make automated and assisted decisions for refunds based on consumer fraud risk.',
    products: RETURNS_ORCHESTRATOR,
    environment: 'SANDBOX',
    lastPublished: '',
  },
  {
    id: 3,
    name: 'Consumer Trust Index',
    description:
      'Safeguarding the entire online marketplace, ensuring a secure and seamless shopping environment for all.',
    products: CONSUMER_TRUST_INDEX,
    environment: 'SANDBOX',
    lastPublished: '',
  },
  {
    id: 4,
    name: 'Returns Orchestrator',
    description:
      'An orchestrator helping operations team make automated and assisted decisions for refunds based on consumer fraud risk.',
    products: RETURNS_ORCHESTRATOR,
    environment: 'LIVE',
    lastPublished: '',
  },
];

export const projects = [
  {
    api_key: 'sandbox_6b9e73d4-f845-41c9-ad8a-ee8757ec7c11',
    id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
    name: 'Consumer Trust Index',
    description:
      'Safeguarding the entire online marketplace, ensuring a secure and seamless shopping environment for all.',
    products: CONSUMER_TRUST_INDEX,
    environment: 'LIVE',
    status: 'draft',
    project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
    company_id: '60c3e8b0-8a2c-11ee-9f7e-e31dc2387503',
    createdAt: '2023-11-27T20:49:37.000Z',
    updatedAt: '2023-11-27T20:49:37.000Z',
    deletedAt: null,
    shopper_trust_index_configs: [
      {
        id: 'aca9e83a-41bc-47dd-8380-acaa5a3bd3a6',
        name: 'Identity',
        config_type: 'BANK_VERIFIED_IDENTITY',
        score: '10.00',
        recommended: '0.00',
        description:
          'Identity verification based on bank-verified identity & digital identity.',
        project_id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
        environment: 'LIVE',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
      {
        id: 'a5fbaacb-364b-4fcd-99da-a931a2e6747d',
        name: 'Network history with consumer',
        config_type: 'NETWORK_HISTORY_WITH_CONSUMER',
        score: '30.00',
        recommended: '0.00',
        description:
          "History of consumer's orders, returns and refunds in across the network including other ecommerce players.",
        project_id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
        environment: 'LIVE',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
      {
        id: '758d101d-5dc2-4202-a2f2-b53e06925aaf',
        name: 'Adverse media scan',
        config_type: 'ADVERSE_MEDIA_SCAN',
        score: '4.00',
        recommended: '0.00',
        description: 'Web crawler for consumer activity on the dark web.',
        project_id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
        environment: 'LIVE',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },

      {
        id: '3d61acd7-ce6c-48e8-a055-97fb9364f62a',
        name: 'Consumer banking history',
        config_type: 'CONSUMER_BANKING_HISTORY',
        score: '35.00',
        recommended: '0.00',
        description:
          'Consumer bank activity levels including shopping returns and refunds history.',
        project_id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
        environment: 'LIVE',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },

      {
        id: '1f3326a5-a4d6-4b22-8cc7-ddf9ce80e481',
        name: "Retailer's own shopping history with consumer",
        config_type: 'RETAILERS_OWN_SHOPPING_HISTORY_WITH_CONSUMER',
        score: '20.00',
        recommended: '0.00',
        description: 'Description...',
        project_id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
        environment: 'LIVE',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
    ],
    trusted_shopper_score_configs: [
      {
        id: 'ce39fa69-e83d-4782-9305-2d3d875f489e',
        config_type: 'MEDIUM_RISK',
        name: 'A Medium Risk Consumer has an index score of...',
        score: '79.00',
        description:
          'Medium Risk Customerdshave a high assurance on their identity and a considerable ratio of returns and refunds, but no detected fraud.',
        recommended: '79.00',
        project_id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
        environment: 'LIVE',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
      {
        id: '1ae1bb6f-ec96-48e6-ae94-e5651ca2d948',
        config_type: 'LOW_RISK',
        name: 'A Trusted Consumer has an index score of ...',
        score: '69.00',
        description:
          'Trusted Customerdshave a high assurance on their identity and a low ratio of returns and refunds across the network.',
        recommended: '69.00',
        project_id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
        environment: 'LIVE',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
      {
        id: '13813ce4-28aa-4a7c-af76-d487615bcbf4',
        config_type: 'HIGH_RISK',
        name: 'A High Risk Consumer has an index score of...',
        score: '80.00',
        description:
          'High Risk Customerdshave medium to low levels on identity assurance, high ratio of returns and refunds and have return and refund issues including fraud.',
        recommended: '80.00',
        project_id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
        environment: 'LIVE',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
    ],
  },
  {
    api_key: 'live_8e6b75ab-cbf3-4ac0-81e5-e07a7bacdc80',
    id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
    name: 'Returns Orchestrator',
    description:
      'An orchestrator helping operations team make automated and assisted decisions for refunds based on consumer fraud risk.',
    products: RETURNS_ORCHESTRATOR,
    environment: 'SANDBOX',
    status: 'draft',
    project_id: null,
    company_id: '60c3e8b0-8a2c-11ee-9f7e-e31dc2387503',
    createdAt: '2023-11-27T20:49:37.000Z',
    updatedAt: '2023-11-27T20:49:37.000Z',
    deletedAt: null,
    shopper_trust_index_configs: [
      {
        id: 'e5628620-103d-467f-a006-4d35b40519cd',
        name: 'Adverse media scan',
        config_type: 'ADVERSE_MEDIA_SCAN',
        score: '4.00',
        recommended: '0.00',
        description: 'Web crawler for consumer activity on the dark web.',
        project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
        environment: 'SANDBOX',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
      {
        id: 'a0d07d1f-0e1a-4f01-9cbe-c429afbb1c1f',
        name: 'Network history with consumer',
        config_type: 'NETWORK_HISTORY_WITH_CONSUMER',
        score: '30.00',
        recommended: '0.00',
        description:
          "History of consumer's orders, returns and refunds in across the network including other ecommerce players.",
        project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
        environment: 'SANDBOX',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
      {
        id: '9f21bc55-861a-400f-b871-15ecbcba3ee9',
        name: 'Consumer banking history',
        config_type: 'CONSUMER_BANKING_HISTORY',
        score: '35.00',
        recommended: '0.00',
        description:
          'Consumer bank activity levels including shopping returns and refunds history.',
        project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
        environment: 'SANDBOX',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },

      {
        id: '4f15255d-2935-49f8-9270-f9a65019b7ad',
        name: 'Identity',
        config_type: 'BANK_VERIFIED_IDENTITY',
        score: '10.00',
        recommended: '0.00',
        description:
          'Identity verification based on bank-verified identity & digital identity.',
        project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
        environment: 'SANDBOX',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },

      {
        id: '36b7136d-fe51-4118-87b9-31e6d710e63a',
        name: "Retailer's own shopping history with consumer",
        config_type: 'RETAILERS_OWN_SHOPPING_HISTORY_WITH_CONSUMER',
        score: '20.00',
        recommended: '0.00',
        description: 'Description...',
        project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
        environment: 'SANDBOX',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
    ],
    trusted_shopper_score_configs: [
      {
        id: 'ce94cf7a-13eb-453d-9318-849cfb4c52b2',
        config_type: 'HIGH_RISK',
        name: 'A High Risk Consumer has an index score of...',
        score: '80.00',
        description:
          'High Risk Customerdshave medium to low levels on identity assurance, high ratio of returns and refunds and have return and refund issues including fraud.',
        recommended: '80.00',
        project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
        environment: 'SANDBOX',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
      {
        id: 'a77e8e76-0344-4e80-94e0-ea7481552d42',
        config_type: 'LOW_RISK',
        name: 'A Trusted Consumer has an index score of ...',
        score: '69.00',
        description:
          'Trusted Customerdshave a high assurance on their identity and a low ratio of returns and refunds across the network.',
        recommended: '69.00',
        project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
        environment: 'SANDBOX',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
      {
        id: '6f2b4927-a381-4700-b39a-8823ec57062e',
        config_type: 'MEDIUM_RISK',
        name: 'A Medium Risk Consumer has an index score of...',
        score: '79.00',
        description:
          'Medium Risk Customerdshave a high assurance on their identity and a considerable ratio of returns and refunds, but no detected fraud.',
        recommended: '79.00',
        project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
        environment: 'SANDBOX',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
    ],
  },
  {
    api_key: 'sandbox_6b9e73d4-f845-41c9-ad8a-ee8757ec7c11',
    id: '10942e91-2c74-44c0-b78a-ed75c75f291E',
    name: 'Consumer Trust Index',
    description:
      'Safeguarding the entire online marketplace, ensuring a secure and seamless shopping environment for all.',
    products: CONSUMER_TRUST_INDEX,
    environment: 'SANDBOX',
    status: 'draft',
    project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
    company_id: '60c3e8b0-8a2c-11ee-9f7e-e31dc2387503',
    createdAt: '2023-11-27T20:49:37.000Z',
    updatedAt: '2023-11-27T20:49:37.000Z',
    deletedAt: null,
    shopper_trust_index_configs: [
      {
        id: 'aca9e83a-41bc-47dd-8380-acaa5a3bd3a6',
        name: 'Identity',
        config_type: 'BANK_VERIFIED_IDENTITY',
        score: '10.00',
        recommended: '0.00',
        description:
          'Identity verification based on bank-verified identity & digital identity.',
        project_id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
        environment: 'LIVE',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
      {
        id: 'a5fbaacb-364b-4fcd-99da-a931a2e6747d',
        name: 'Network history with consumer',
        config_type: 'NETWORK_HISTORY_WITH_CONSUMER',
        score: '30.00',
        recommended: '0.00',
        description:
          "History of consumer's orders, returns and refunds in across the network including other ecommerce players.",
        project_id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
        environment: 'LIVE',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
      {
        id: '758d101d-5dc2-4202-a2f2-b53e06925aaf',
        name: 'Adverse media scan',
        config_type: 'ADVERSE_MEDIA_SCAN',
        score: '4.00',
        recommended: '0.00',
        description: 'Web crawler for consumer activity on the dark web.',
        project_id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
        environment: 'LIVE',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },

      {
        id: '3d61acd7-ce6c-48e8-a055-97fb9364f62a',
        name: 'Consumer banking history',
        config_type: 'CONSUMER_BANKING_HISTORY',
        score: '35.00',
        recommended: '0.00',
        description:
          'Consumer bank activity levels including shopping returns and refunds history.',
        project_id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
        environment: 'LIVE',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },

      {
        id: '1f3326a5-a4d6-4b22-8cc7-ddf9ce80e481',
        name: "Retailer's own shopping history with consumer",
        config_type: 'RETAILERS_OWN_SHOPPING_HISTORY_WITH_CONSUMER',
        score: '20.00',
        recommended: '0.00',
        description: 'Description...',
        project_id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
        environment: 'LIVE',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
    ],
    trusted_shopper_score_configs: [
      {
        id: 'ce39fa69-e83d-4782-9305-2d3d875f489e',
        config_type: 'MEDIUM_RISK',
        name: 'A Medium Risk Consumer has an index score of...',
        score: '79.00',
        description:
          'Medium Risk Customerdshave a high assurance on their identity and a considerable ratio of returns and refunds, but no detected fraud.',
        recommended: '79.00',
        project_id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
        environment: 'LIVE',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
      {
        id: '1ae1bb6f-ec96-48e6-ae94-e5651ca2d948',
        config_type: 'LOW_RISK',
        name: 'A Trusted Consumer has an index score of ...',
        score: '69.00',
        description:
          'Trusted Customerdshave a high assurance on their identity and a low ratio of returns and refunds across the network.',
        recommended: '69.00',
        project_id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
        environment: 'LIVE',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
      {
        id: '13813ce4-28aa-4a7c-af76-d487615bcbf4',
        config_type: 'HIGH_RISK',
        name: 'A High Risk Consumer has an index score of...',
        score: '80.00',
        description:
          'High Risk Customerdshave medium to low levels on identity assurance, high ratio of returns and refunds and have return and refund issues including fraud.',
        recommended: '80.00',
        project_id: '10942e91-2c74-44c0-b78a-ed75c75f291d',
        environment: 'LIVE',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
    ],
  },
  {
    api_key: 'live_8e6b75ab-cbf3-4ac0-81e5-e07a7bacdc80',
    id: '7b409b4d-80c7-4977-b4a2-1f28cd4a815E',
    name: 'Returns Orchestrator',
    description:
      'An orchestrator helping operations team make automated and assisted decisions for refunds based on consumer fraud risk.',
    products: RETURNS_ORCHESTRATOR,
    environment: 'LIVE',
    status: 'draft',
    project_id: null,
    company_id: '60c3e8b0-8a2c-11ee-9f7e-e31dc2387503',
    createdAt: '2023-11-27T20:49:37.000Z',
    updatedAt: '2023-11-27T20:49:37.000Z',
    deletedAt: null,
    shopper_trust_index_configs: [
      {
        id: 'e5628620-103d-467f-a006-4d35b40519cd',
        name: 'Adverse media scan',
        config_type: 'ADVERSE_MEDIA_SCAN',
        score: '4.00',
        recommended: '0.00',
        description: 'Web crawler for consumer activity on the dark web.',
        project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
        environment: 'SANDBOX',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
      {
        id: 'a0d07d1f-0e1a-4f01-9cbe-c429afbb1c1f',
        name: 'Network history with consumer',
        config_type: 'NETWORK_HISTORY_WITH_CONSUMER',
        score: '30.00',
        recommended: '0.00',
        description:
          "History of consumer's orders, returns and refunds in across the network including other ecommerce players.",
        project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
        environment: 'SANDBOX',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
      {
        id: '9f21bc55-861a-400f-b871-15ecbcba3ee9',
        name: 'Consumer banking history',
        config_type: 'CONSUMER_BANKING_HISTORY',
        score: '35.00',
        recommended: '0.00',
        description:
          'Consumer bank activity levels including shopping returns and refunds history.',
        project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
        environment: 'SANDBOX',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },

      {
        id: '4f15255d-2935-49f8-9270-f9a65019b7ad',
        name: 'Identity',
        config_type: 'BANK_VERIFIED_IDENTITY',
        score: '10.00',
        recommended: '0.00',
        description:
          'Identity verification based on bank-verified identity & digital identity.',
        project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
        environment: 'SANDBOX',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },

      {
        id: '36b7136d-fe51-4118-87b9-31e6d710e63a',
        name: "Retailer's own shopping history with consumer",
        config_type: 'RETAILERS_OWN_SHOPPING_HISTORY_WITH_CONSUMER',
        score: '20.00',
        recommended: '0.00',
        description: 'Description...',
        project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
        environment: 'SANDBOX',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
    ],
    trusted_shopper_score_configs: [
      {
        id: 'ce94cf7a-13eb-453d-9318-849cfb4c52b2',
        config_type: 'HIGH_RISK',
        name: 'A High Risk Consumer has an index score of...',
        score: '80.00',
        description:
          'High Risk Customerdshave medium to low levels on identity assurance, high ratio of returns and refunds and have return and refund issues including fraud.',
        recommended: '80.00',
        project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
        environment: 'SANDBOX',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
      {
        id: 'a77e8e76-0344-4e80-94e0-ea7481552d42',
        config_type: 'LOW_RISK',
        name: 'A Trusted Consumer has an index score of ...',
        score: '69.00',
        description:
          'Trusted Customerdshave a high assurance on their identity and a low ratio of returns and refunds across the network.',
        recommended: '69.00',
        project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
        environment: 'SANDBOX',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
      {
        id: '6f2b4927-a381-4700-b39a-8823ec57062e',
        config_type: 'MEDIUM_RISK',
        name: 'A Medium Risk Consumer has an index score of...',
        score: '79.00',
        description:
          'Medium Risk Customerdshave a high assurance on their identity and a considerable ratio of returns and refunds, but no detected fraud.',
        recommended: '79.00',
        project_id: '7b409b4d-80c7-4977-b4a2-1f28cd4a8158',
        environment: 'SANDBOX',
        createdAt: '2023-11-27T20:49:38.000Z',
        updatedAt: '2023-11-27T20:49:38.000Z',
        deletedAt: null,
      },
    ],
  },
];

export const getMonthRange = (startDate: Date, endDate: Date) => {
  const resp = [];
  const activeDate = moment(startDate);
  while (activeDate.isSameOrBefore(moment(endDate))) {
    resp.push(String(activeDate.format('MMM DD')));
    activeDate.add(1, 'day');
  }
  return resp;
};

// Network Risk Distribution Series Value
export const seriesValue = (value: any[]) => {
  let result = [];
  if (value.length) {
    result = value.map((item: any) => item.value).reverse();
  }
  return result;
};

export const generateFirstLetter = (name: string | undefined) => {
  return name ? name.trim().slice(0, 1).toUpperCase() : '';
};

export const isSubmittable = (
  currentValues: { [key: string]: any },
  initialValues: { [key: string]: any },
  validateKeys: string[]
) => {
  let isValidDataFilled = true;
  validateKeys.forEach((data) => {
    if (currentValues[data] === initialValues[data]) {
      isValidDataFilled = false;
    }
  });
  return isValidDataFilled;
};

export const renderNameIcon = ({
  name,
  isCircle,
  className,
  textClassName,
  imageUrl,
  isLimitLongText = false,
}: {
  name: string;
  isCircle?: boolean;
  className?: string;
  textClassName?: string;
  imageUrl?: string;
  isLimitLongText?: boolean;
}) => {
  return (
    <div className="flex justify-start">
      {imageUrl ? (
        <img
          alt=""
          src={imageUrl}
          className={`w-[22px] h-[22px] rounded ${className ?? ''} ${isCircle ? ' rounded-xl' : ''}`}
        />
      ) : (
        <NameBadge
          FirstName={name}
          parentClass={`${isCircle ? ' rounded-xl' : ''} ${className ?? ''}`}
        />
      )}

      <h6 className={` ${textClassName ?? ''}`}>
        {isLimitLongText && name?.length > 20 ? `${name.substring(0, 20)}...` : name}
      </h6>
    </div>
  );
};

export const wait = (seconds: number): Promise<void> => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000); // converting seconds to milliseconds
  });
};

export const getRoleColor = (role: string) => {
  switch (role) {
    case ROLES.ADMIN:
      return 'bg-blurple';
    case ROLES.EDITOR:
      return 'bg-trud-orange';
    case ROLES.VIEWER:
      return 'bg-risk-green';
    default:
      return 'bg-body-black';
  }
};

export const ShopperScoreOrder = (shopperscore: Config[]) => {
  return shopperscore?.length
    ? (ShopperConfigSequence.map((config_type) =>
        shopperscore.find((obj) => obj.config_type === config_type)
      ).filter((score) => score != undefined) as Config[])
    : [];
};

export const PTIProjectDetails = {
  api_key: 'sandbox_e4ef8457-7195-4f92-a29a-ada9df062254',
  id: 'e3ccda88-401b-4683-b6be-1f8f010c5fc9',
  name: 'APP Fraud Prevention',
  description:
    'Identify bank accounts associated with APP fraud and offering a name-to-IBAN match.',
  environment: 'SANDBOX',
  products: PAYEE_TRUST_INDEX,
  status: 'published',
  fraud_risk_type_id: '468bfc5d-b7b2-4e54-9051-e55bc2013ef6',
  project_id: 'dd3ada11-ed52-4626-9c5a-a1c0df9afe74',
  organization_id: '',
  published_at: null,
  api_key_detail: {
    api_key: 'sandbox_e4ef8457-7195-4f92-a29a-ada9df062254',
    environment: 'SANDBOX',
    project_id: 'dd3ada11-ed52-4626-9c5a-a1c0df9afe74',
  },
  projects: null,
  fraud_risk_type: {
    id: '468bfc5d-b7b2-4e54-9051-e55bc2013ef6',
    title: 'Payee trustworthiness',
    description:
      'Confirmation of Payee and an assessment of previous APP fraud and scam activity linked to the account.',
    type: 'PRESETS',
  },
  trusted_shopper_scores: [
    {
      id: 'a7d6ea94-6203-481c-a204-80c2021f5d0c',
      name: 'A Trusted Payee has an index score of ...',
      score: 80,
      config_type: 'LOW_RISK' as config_type,
      recommended: 80,
      description:
        'Trusted Payees can be confirmed in an account-to-name match and have no cases of reported APP fraud scams activity against their accounts.',
      environment: 'SANDBOX',
      project_id: 'bbc76fef-83d8-44c1-bc87-d26d879138be',
    },
    {
      id: '56903c72-921a-4e62-b458-0846b0873185',
      name: 'A Medium Risk Payee has an index score of...',
      score: '79',
      config_type: 'MEDIUM_RISK' as config_type,
      recommended: '79',
      description:
        'Medium Risk Payees can be confirmed in an account-to-name match  but have cases of reported APP fraud scams activity against their accounts.',
      environment: 'SANDBOX',
      project_id: 'bbc76fef-83d8-44c1-bc87-d26d879138be',
    },
    {
      id: '1aff524a-4d49-4598-a733-5515ec6d7121',
      name: 'A High Risk Payee has an index score of...',
      score: '69',
      config_type: 'LOW_RISK' as config_type,
      recommended: '69',
      description:
        'High Risk Payees cannot be confirmed in an account-to-name match and/or have cases of reported APP fraud scams activity against their accounts.',
      environment: 'SANDBOX',
      project_id: 'bbc76fef-83d8-44c1-bc87-d26d879138be',
    },
  ],
  low_risk_shopper_options: {
    'Pay after delivery': true,
    'Pay by installments': true,
    'Debit/Credit card': true,
    'Pay by Bank': true,
    'Apple Pay': true,
    'Google Pay': true,
  },
  medium_risk_shopper_options: {
    'Pay after delivery': false,
    'Pay by installments': true,
    'Debit/Credit card': true,
    'Pay by Bank': true,
    'Apple Pay': true,
    'Google Pay': true,
  },
  high_risk_shopper_options: {
    'Pay after delivery': false,
    'Pay by installments': false,
    'Debit/Credit card': true,
    'Pay by Bank': true,
    'Apple Pay': true,
    'Google Pay': true,
  },
  low_risk_refund_details: {
    refund_mode: 'Instant refund',
    refund_trigger: 'Received by logistics',
    refund_execution: 'Immediately',
  },
  medium_risk_refund_details: {
    refund_mode: 'Refund after return',
    refund_trigger: 'Received in warehouse',
    refund_execution: 'After',
    days_to_refund: 14,
  },
  high_risk_refund_details: {
    refund_mode: 'Refund after return',
    refund_trigger: 'Approved for refund',
    refund_execution: 'After',
    days_to_refund: 14,
  },
};

export const PAYEE_TRUST_INDEX_ATTRIBUTES = [
  {
    id: 'e3b3710e-df5c-42aa-b0da-e4babcfae5d4',
    name: 'Banking',
    score: '50',
    config_type: 'BANKING',
    description: 'Confirmation of Payee, providing a name to account match',
    recommended: '50',
    sub_category: [
      {
        name: 'Name and account match',
        score: '50',
        description:
          'Verification of the recipient’s name and account as an exact match, no match or close match.',
        recommended: '50',
      },
    ],
  },
  {
    id: 'e3b3710e-df5c-42aa-b0da-e4babcfae5d5',
    name: 'Issuer data',
    score: '25',
    config_type: 'ISSUER_DATA',
    description:
      'Data shared by your organisation about accounts involved with APP fraud and scams.',
    recommended: '25',
    sub_category: [
      {
        name: 'APP fraud activity',
        score: '25',
        description:
          'Actual evidence of APP fraud and scam activity into the account based on the Payer’s Institution’s data',
        recommended: '25',
      },
    ],
  },
  {
    id: 'e3b3710e-df5c-42aa-b0da-e4babcfae5d6',
    name: 'Network intelligence',
    score: '25',
    config_type: 'NETWORK_INTELLIGENCE',
    description:
      'Data shared by other Issuers about accounts involved with APP fraud and scams.',
    recommended: '25',
    sub_category: [
      {
        name: 'APP fraud activity',
        score: '25',
        description:
          'Actual evidence of APP fraud and scam activity into the account based on data shared across the network',
        recommended: '25',
      },
    ],
  },
];

export const checkConsentAuthorisation = ({
  consent_status,
  request_timestamp,
}: {
  consent_status: string;
  request_timestamp: Date;
}) => {
  if (consent_status === 'ACTIVE' && moment(request_timestamp).isAfter(moment())) {
    return true;
  }
  return false;
};

export const generateLastMonthsList = (year: string) => {
  const lastMonthsList = [];
  const currentMonths =
    year === moment().year().toString() ? moment().month() : 11;

  for (let i = currentMonths; i >= 0; i--) {
    lastMonthsList.push({
      label: moment().month(i).format(`MMM`).toString().toUpperCase(),
      value: year + "-" + moment().month(i).format("MM"),
    });
  }
  return lastMonthsList;
};